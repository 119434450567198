/*********************************
*********** + Слайдер ************
*********************************/

var Slider = function (items, selectors, options) {
    this.boxSlider = selectors.container;
    this.buttons = selectors.navigation;

    this.items = items;

    /*Навигация с текстом*/
    this.textNavButtons = selectors.textNavButtons || 0;

    /**/

    /**
     * Current options set by the caller including defaults.
     * @public
     */
    this.options = $.extend({}, Slider.Defaults, options);
    this.options.loadTryLimit =  items.length*4;

    this.construct();

    this.boxSlider.after('<img src="'+ this.options.directoryLoader +'loader.gif" alt="" class="preload" />');
}

/**
 * Конструктор блоков слайдера
 * @return {[void]}
 */
Slider.prototype.construct = function () {
    $.each(this.items, function(i, item) {
        var itemSlide = item[1]
            ? $('<div class="slider__item"><a href="'+item[1]+'"><img src="' + this.options.directory + item[0] + '"  alt="" /></a></div>')
            : $('<div class="slider__item"><img src="' + this.options.directory + item[0] + '" alt="" /></div>');

        if (item[2]) itemSlide.find('img').after('<span class="slider__item__text"><span>'+item[2]+'</span></span>');
        if (item[3]) itemSlide.find('.slider__item__text').append('<a href="'+item[4]+'" class="button button_standart button_transparent">'+item[3]+'</a>');

        /* Навигация с текстом */

        if (this.options.textNavigation) {
            var par = $('<div>'+item[3]+'</div>').data('index', i)
            this.textNavButtons.append(par);
        }

        /**/

        this.boxSlider.append(itemSlide);
        var point = $('<div></div>').data('index', i);
        this.buttons.append(point);
        this.imgLoad(itemSlide.find('img'));
    }.bind(this));

    /* Навигация prev next*/
    if (this.options.prevNext) this.boxSlider.after('<div class="slider__prev"></div><div class="slider__next"></div>');
    /**/

    this.testAllImagesLoaded(this.boxSlider.find('img'));

    if (this.buttons.find('div').length > 1) {
        //this.buttons = sliderEvents.$buttons;
        this.showButtons();
    }
}

/**
 * Когда изображение загружено, оно получает статус в data 'loaded' = 1
 * @param  {[Object]} item [Селектор блока слайдера]
 * @return {[void]}
 */

Slider.prototype.imgLoad = function (item) {
    item[0].onload = function() {
        $(this).data('loaded', 1);
    };
}

/**
 * Default options for the carousel.
 * @public
 */
Slider.Defaults = {
    firstLoad: true,
    indexItem: 0,
    animStop: 0,
    duration: 5000,
    timeout: 0,
    allImagesLoaded: 0,
    errorLoad: 0,
    courseAnimate: true,
    textNavigation: false,
    prevNext: false,
    //buttons: 0
};

/**
 * Изменение статуса кнопок навигации
 * @return {[void]}
 */

 Slider.prototype.statusButtons = function () {
     this.buttons.find('.sel').removeClass('sel');
     this.buttons.find('div').eq(this.options.indexItem).addClass('sel');

     if (this.options.textNavigation) {
         this.textNavButtons.find('.sel').removeClass('sel');
         this.textNavButtons.find('div').eq(this.options.indexItem).addClass('sel');
     }
 }


/**
 * Обработчик нажатия кнопок навигации
 * @return {[void]}
 */

Slider.prototype.buttonsEvents = function () {
    var action, newIndex;

    this.buttons.find('div').click(function (event) {
        if (!this.options.animStop && !$(event.target).hasClass('sel')) {
            clearTimeout(this.options.timeout);
            newIndex = $(event.target).data('index');
            this.options.courseAnimate = newIndex < this.options.indexItem ? false : true;
            this.options.indexItem = newIndex;
            this.animateItem();
        }
    }.bind(this));

    if (this.options.textNavigation) {
        this.textNavButtons.find('div').click(function () {
            if (!this.options.animStop && !$(event.target).hasClass('sel')) {
                clearTimeout(this.options.timeout);
                newIndex = $(event.target).data('index');
                this.options.courseAnimate = newIndex < this.options.indexItem ? false : true;
                this.options.indexItem = newIndex;
                this.animateItem();
            }
        }.bind(this));
    }

    if (this.options.prevNext) {
        this.boxSlider.parent().find('.slider__prev').click(function () {
            if (!this.options.animStop) {
                clearTimeout(this.options.timeout);
                this.getIndexNextSlide('prev');
            }
        }.bind(this));
        this.boxSlider.parent().find('.slider__next').click(function () {
            if (!this.options.animStop) {
                clearTimeout(this.options.timeout);
                this.getIndexNextSlide('next');
            }
        }.bind(this));
    }
}

/**
 * Если все изображения в слайдере загружены, показываем кнопки навигации
 * @return {[void]}
 */

Slider.prototype.showButtons = function () {
    if (this.options.allImagesLoaded && this.items.length > 1) {
        this.buttons.show();
        if (this.options.textNavigation) this.textNavButtons.show();
        if (this.options.prevNext) {
            this.boxSlider.parent().find('.slider__prev').show();
            this.boxSlider.parent().find('.slider__next').show();
        }
        this.buttonsEvents();
    } else if (!this.options.errorLoad) {
        setTimeout(function() {
            this.showButtons();
        }.bind(this), 100);
    }
    //this.buttonsEvents();
}

/**
 * Получение индекса следующего анимируемого блока и вызов анимации
 * @param  {[string]} action [Указыаем, как рассчитать индекс +/-]
 * @return {[void]}
 */

Slider.prototype.getIndexNextSlide = function (action) {
    action = action || 'next';
    switch (action) {
        case 'next': {
            this.options.indexItem += 1;
            if (this.options.indexItem == this.items.length) this.options.indexItem = 0;
        } break;
        case 'prev': {
            this.options.indexItem -= 1;
            if (this.options.indexItem < 0) this.options.indexItem = this.items.length - 1;
        } break;
    }

    this.animateItem();
}

/**
 * Если все изображения загружены и в слайдере > 1 блока, запуск таймаута перед сменой слайда
 * @param  {[string]} action [Указыаем, как рассчитывать индекс следующего анимируемого слайда +/-]
 * @return {[void]}
 */

Slider.prototype.chooseSlide = function (action) {
    if (this.options.allImagesLoaded && this.items.length > 1 && !this.options.animStop) {
        this.options.timeout = setTimeout(function() {
            this.getIndexNextSlide(action);
        }.bind(this), this.options.duration);
    } else {
        setTimeout(function() {
            this.chooseSlide(action);
        }.bind(this), 100);
    }
}

// анимация влево-вправо

/*Slider.prototype.animateItem = function () {
    var visibleItem, vleft;
    this.options.animStop = 1;

    vleft = this.options.courseAnimate == true ? 100 : -100;

    if (this.boxSlider.find('.vis').length) {
        visibleItem = this.boxSlider.find('.vis');
        visibleItem.animate({left: -vleft+'%'}, 400, 'linear', function() { $(this).removeClass('vis'); });
    }
    this.boxSlider.find('.slider__item').eq(this.options.indexItem).css('left', vleft+'%').addClass('vis').animate({left: "0"}, 400, 'linear', function() {
        this.options.animStop = 0;
        this.options.courseAnimate = true;
        this.chooseSlide('next');
    }.bind(this));

    if (this.buttons != undefined) this.statusButtons();
}*/

/**
 * Анимация из прозрачности
 * @return {[void]}
 */

Slider.prototype.animateItem = function () {
    var visibleItem;
    this.options.animStop = 1;

    if (this.boxSlider.find('.vis').length)  {
        visibleItem = this.boxSlider.find('.vis');
        visibleItem.animate({opacity: 0}, 700, 'linear', function() { $(this).removeClass('vis'); });
    }
    this.boxSlider.find('.slider__item').eq(this.options.indexItem).addClass('vis').animate({opacity: 1}, 700, 'linear', function() {
        this.options.animStop = 0;
        this.chooseSlide('next');
    }.bind(this));

    if (this.buttons != undefined) this.statusButtons();
}

/**
 * Проверяем, загрузились ли все изображения слайдера.
 * Если загружено первое, показываем первый слайд, удаляем loader.
 * Если все загружены, переменная allImagesLoaded = 1
 * @return {[void]}
 */

Slider.prototype.testAllImagesLoaded = function (imagesAll) {
    var counter = 0;

    for (var i = 0; i < imagesAll.length; i++) {
        if (imagesAll.eq(i).data('loaded') == 1) counter ++;
    }

    if (imagesAll.eq(0).data('loaded') == 1 && this.options.firstLoad) {
        this.options.firstLoad = false;
        this.boxSlider.next('.preload').remove();
        this.animateItem();
    }

    if (counter == imagesAll.length) this.options.allImagesLoaded = 1;
    else {
        this.options.loadTryLimit--;
        if (this.options.loadTryLimit) {
            setTimeout(function() {
                this.testAllImagesLoaded(imagesAll);
            }.bind(this), 500);
        } else this.options.errorLoad = 1;
    }
}
